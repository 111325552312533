import "./contact.scss";

import React from "react";

function Contact() {
  return (
    <div className="contactContainer">
      <h2>Contact: </h2>
      <h2>086 1799683</h2>
    </div>
  );
}

export default Contact;
